'use client';
import { useIsomorphicLayoutEffect } from '@/hooks/use-isomorphic-layout-effect';
import { getAcceptedTerms, getSession } from '@/services/local-services';
import { useGetToken } from '@/services/token.client';
import { createContext, useContext, useEffect, useState } from 'react';

export type AuthCtxProps = {
  token: string | null;
  acceptedTerms: boolean;
  isLoading: boolean;
  setHasAcceptedTerms: (v: boolean) => void;
  setShowCountdownAlert: (v: boolean) => void;
  showCountdownAlert: boolean;
};

export const AuthContext = createContext<AuthCtxProps>({} as AuthCtxProps);

export const useAuthCtx = () => useContext(AuthContext);

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const session = getSession();
  const acceptedTerms = getAcceptedTerms();
  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(acceptedTerms);
  const [isPending, setIsPending] = useState(true);
  const [token, setToken] = useState<string | null>(session);

  const [showCountdownAlert, setShowCountdownAlert] = useState(true);

  const { data } = useGetToken(session);

  useIsomorphicLayoutEffect(() => {
    setHasAcceptedTerms(acceptedTerms);
    setIsPending(false);
  }, []);

  useEffect(() => {
    if (data) {
      setToken(data);
    }
  }, [data]);

  // useEffect(() => {
  //   const captureLogo = document.querySelector('.grecaptcha-badge');
  //   const isCaptureHidden = captureLogo?.classList?.contains?.('!hidden');

  //   if (!pathname.includes('/join') && !isCaptureHidden) {
  //     captureLogo?.classList.add('!hidden');
  //   } else if (pathname == '/join' && isCaptureHidden) {
  //     captureLogo?.classList.remove('!hidden');
  //   }
  // }, [pathname]);

  return (
    <AuthContext.Provider
      value={{
        token,
        acceptedTerms: hasAcceptedTerms,
        setHasAcceptedTerms,
        isLoading: isPending,
        setShowCountdownAlert,
        showCountdownAlert,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
